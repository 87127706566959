import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import {ExpandMore as ExpandMoreIcon, Info as InfoIcon} from "@material-ui/icons"

import {CreatedActivity} from "./CreatedActivity"
import {SMSSentActivity} from "./SMSSentActivity"
import {EmailSentActivity} from "./EmailSentActivity"
import {AddressChangedActivity} from "./AddressChangedActivity"
import {CaseEscalatedActivity} from "./CaseEscalatedActivity"
import {CommunicationSnoozedActivity} from "./CommunicationSnoozedActivity"
import {EmailAddedActivity} from "./EmailAddedActivity"
import {EmailRemovedActivity} from "./EmailRemovedActivity"
import {PhoneAddedActivity} from "./PhoneAddedActivity"
import {PhoneRemovedActivity} from "./PhoneRemovedActivity"
import {PhoneIdentityScrubActivity} from "./PhoneIdentityScrubActivity"
import {InformationUpdatedActivity} from "./InformationUpdatedActivity"
import {EmailCommunicationsOptedOutActivity} from "./EmailCommunicationsOptedOutActivity"
import {EmailCommunicationsOptedInActivity} from "./EmailCommunicationsOptedInActivity"
import {PreferredLanguageConfiguredActivity} from "./PreferredLanguageConfiguredActivity"
import {DigitalCommunicationConsentGrantedActivity} from "./DigitalCommunicationConsentGrantedActivity"
import {DigitalCommunicationConsentDeniedActivity} from "./DigitalCommunicationConsentDeniedActivity"
import {ESIGNConsentRequestedActivity} from "./ESIGNConsentRequestedActivity"
import {ESIGNConsentGrantedActivity} from "./ESIGNConsentGrantedActivity"
import {ESIGNConsentRevokedActivity} from "./ESIGNConsentRevokedActivity"
import {EmailLeadsAddedActivity} from "./EmailLeadsAddedActivity"
import {EmailLeadRemovedActivity} from "./EmailLeadRemovedActivity"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	summary: {
		fontSize: theme.typography.pxToRem(15),
		flexShrink: 0,
		paddingLeft: 8,
	},
	activityContent: {
		paddingLeft: 15,
	},
	activityContentLabel: {
		fontSize: theme.typography.pxToRem(15),
		flexShrink: 0,
	},
	activityContentValue: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
		paddingBottom: 15,
	},
	jsonContent: {
		paddingLeft: 15,
	},
	jsonContentInfo: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
		paddingBottom: 15,
	},
}))

function List({activities}) {
	const classes = useStyles()
	const [expandedPanel, setExpandedPanel] = React.useState(false)

	const changeExpandedPanelTo = (panel) => (_, currentlyExpanded) => {
		setExpandedPanel(currentlyExpanded ? panel : false)
	}

	const activityPanel = (activity, panelId) => {
		let expanded = expandedPanel === panelId
		switch (activity.Type) {
			case "CustomerCreated":
				return CreatedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "SMSSent":
				return SMSSentActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "EmailSent":
				return EmailSentActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "NOASent":
				return EmailSentActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerAddressChanged":
				return AddressChangedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerCaseEscalated":
				return CaseEscalatedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerCommunicationSnoozed":
				return CommunicationSnoozedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailAdded":
				return EmailAddedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailRemoved":
				return EmailRemovedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerPhoneAdded":
				return PhoneAddedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerPhoneIdentityNotMatchedByScrub":
				return PhoneIdentityScrubActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerPhoneIdentityMatchedByScrub":
				return PhoneIdentityScrubActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerPhoneRemoved":
				return PhoneRemovedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerInformationUpdated":
				return InformationUpdatedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailCommunicationsOptedOut":
				return EmailCommunicationsOptedOutActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailCommunicationsOptedIn":
				return EmailCommunicationsOptedInActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerPreferredLanguageConfigured":
				return PreferredLanguageConfiguredActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerDigitalCommunicationConsentGranted":
				return DigitalCommunicationConsentGrantedActivity(
					panelId,
					activity,
					expanded,
					changeExpandedPanelTo,
					classes,
				)
			case "ESIGNConsentRequested":
				return ESIGNConsentRequestedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "ESIGNConsentGranted":
				return ESIGNConsentGrantedActivity(panelId, activity, expanded, classes)
			case "ESIGNConsentRevoked":
				return ESIGNConsentRevokedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerDigitalCommunicationConsentDenied":
				return DigitalCommunicationConsentDeniedActivity(
					panelId,
					activity,
					expanded,
					changeExpandedPanelTo,
					classes,
				)
			case "CustomerEmailLeadsAddedByDataWashing":
				return EmailLeadsAddedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailLeadRemovedByCustomer":
				return EmailLeadRemovedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			case "CustomerEmailLeadRemovedByThirdParty":
				return EmailLeadRemovedActivity(panelId, activity, expanded, changeExpandedPanelTo, classes)
			default:
				return renderAccordionJSON(
					panelId,
					activity.Type,
					activity.Title,
					activity.HappenedAt,
					activity.Payload,
				)
		}
	}

	const renderAccordionJSON = (Index, Type, Title, HappenedAt, Payload) => {
		const hasContent = JSON.stringify(Payload) != "{}"

		return hasContent
			? renderAccordionJSONContent(Index, Title, HappenedAt, Payload)
			: renderAccordionNoContent(Title, HappenedAt)
	}

	const renderAccordionJSONContent = (Index, Title, HappenedAt, Payload) => (
		<Accordion expanded={expandedPanel === Index} onChange={changeExpandedPanelTo(Index)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<InfoIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{expandedPanel === Index && (
					<div className={classes.jsonContent}>
						<Typography className={classes.jsonContentInfo}>
							<pre>{JSON.stringify(Payload, null, 2)}</pre>
						</Typography>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)

	const renderAccordionNoContent = (Title, HappenedAt) => (
		<AccordionNoContent expanded={false}>
			<AccordionSummary>
				<InfoIcon color="primary" />
				<Typography className={classes.summary}>
					{" "}
					<b>{Title}</b> - {HappenedAt}
				</Typography>
			</AccordionSummary>
		</AccordionNoContent>
	)

	return (
		<div className={classes.root}>
			{activities["Activities"].map((activity, panelId) => (
				<div key={panelId}>{activityPanel(activity, panelId)}</div>
			))}
		</div>
	)
}

const AccordionNoContent = styled(Accordion)`
	.MuiAccordionSummary-content {
		cursor: default;
	}
`

export {List}
