"use strict";
/*
 * Entry point types.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryPointTypesEnum = void 0;
var EntryPointTypesEnum;
(function (EntryPointTypesEnum) {
    EntryPointTypesEnum["CONVERSATION_LINK_DROPDOWN"] = "CONVERSATION_LINK_DROPDOWN";
    EntryPointTypesEnum["MESSAGE_MORE_DROPDOWN"] = "MESSAGE_MORE_DROPDOWN";
    EntryPointTypesEnum["SIDE_PANEL"] = "SIDE_PANEL";
})(EntryPointTypesEnum = exports.EntryPointTypesEnum || (exports.EntryPointTypesEnum = {}));
