"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildApplicationCancelTokenSource = exports.ApplicationCancelError = void 0;
var errorTypesV2_1 = require("./errorTypesV2");
var ApplicationCancelError = /** @class */ (function (_super) {
    __extends(ApplicationCancelError, _super);
    function ApplicationCancelError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ApplicationCancelError;
}(errorTypesV2_1.ApplicationError));
exports.ApplicationCancelError = ApplicationCancelError;
function buildApplicationCancelTokenSource() {
    var resolve = null;
    var wasResolveCalled = false;
    var promise = new Promise(function (promiseResolve) {
        resolve = promiseResolve;
    });
    var cancel = function (message) {
        if (!resolve) {
            throw new Error('Promise initialization failed');
        }
        wasResolveCalled = true;
        resolve({ message: message || 'The process was cancelled.' });
    };
    var throwIfRequested = function () {
        if (wasResolveCalled) {
            throw new ApplicationCancelError();
        }
    };
    var token = {
        promise: promise,
        throwIfRequested: throwIfRequested,
    };
    return {
        cancel: cancel,
        token: token,
    };
}
exports.buildApplicationCancelTokenSource = buildApplicationCancelTokenSource;
