import {Routes, Route, useLocation} from "react-router-dom"
import {Search} from "@indebted/components/Search"
import {Box, Grid, Typography, Tooltip, IconButton} from "@material-ui/core"
import SnoozeIcon from "@material-ui/icons/Snooze"
import WarningIcon from "@material-ui/icons/Warning"
import React from "react"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"

import {Tabs} from "./Tabs"
import {CustomerMenu} from "./CustomerMenu"
import {Overview} from "./Overview"
import {Accounts} from "./Accounts"
import {PaymentPlans} from "./PaymentPlans"
import {Notes} from "./Notes"
import {Transactions} from "./Transactions"
import {Activities} from "./Activities"
import {OfferModal} from "./OfferModal"
import IconDiscount from "./IconDiscount.svg"
import {CustomerEligibleOffersModal} from "./CustomerEligibleOffersModal"

function CustomerCard({customer, reload}) {
	const location = useLocation()
	const search = new URLSearchParams(location.search)
	const [offerData, setOfferData] = React.useState({})
	const {notification} = useNotification()

	function setupDiscountOffer() {
		api.customer
			.getSettlementOffer(customer.ID)
			.then((response) => {
				setOfferData({...response, open: true})
			})
			.catch((error) => {
				notification.error(error.Message)
			})
	}

	function OverviewTab() {
		return (
			<>
				<Tabs index={0} customer={customer} />
				<Overview customer={customer} reload={reload} />
			</>
		)
	}
	function NoteTab() {
		return (
			<>
				<Tabs index={1} customer={customer} />
				<Notes customerID={customer.ID} notes={customer.Notes} reload={reload} />
			</>
		)
	}

	function AccountsTab() {
		return (
			<>
				<Tabs index={2} customer={customer} />
				<Accounts customer={customer} reload={reload} />
			</>
		)
	}

	function TransactionsTab() {
		return (
			<>
				<Tabs index={3} customer={customer} />
				<Transactions transactions={customer.Transactions} reload={reload} />
			</>
		)
	}

	function PaymentPlansTab() {
		return (
			<>
				<Tabs index={4} customer={customer} />
				<PaymentPlans activePaymentPlans={customer.ActivePaymentPlans} reload={reload} />
			</>
		)
	}

	function ActivitiesTab() {
		return (
			<>
				<Tabs index={5} customer={customer} />
				<Activities customerID={customer.ID} reload={reload} />
			</>
		)
	}

	return (
		<>
			<OfferModal offerData={offerData} customerID={customer.ID} />
			<Search value={search.get("q")} backURL={search.get("back")} />
			<Box p={3} pt={0}>
				<Grid container alignItems="center">
					<Grid item style={{display: "flex", alignItems: "center"}}>
						<CustomerStatus customer={customer} />
					</Grid>
					<Grid item style={{flexGrow: 1}}>
						<Typography variant="h5">{customer.FullName}</Typography>
					</Grid>
					<Grid item>
						<CustomerEligibleOffersModal customerID={customer.ID} />
					</Grid>
					{customer.EnableCreateSettlementOffer && (
						<Grid item>
							<IconButton onClick={setupDiscountOffer} title="Settle account with discount">
								<img src={IconDiscount} width={20} />
							</IconButton>
						</Grid>
					)}
					<Grid item>
						<CustomerMenu customer={customer} reload={reload} />
					</Grid>
				</Grid>
			</Box>

			<Routes>
				<Route path="/" element={<OverviewTab />} />
				<Route path="/notes" element={<NoteTab />} />
				<Route path="/accounts/*" element={<AccountsTab />} />
				<Route path="/paymentplans" element={<PaymentPlansTab />} />
				<Route path="/activities/*" element={<ActivitiesTab />} />
				<Route path="/transactions" element={<TransactionsTab />} />
			</Routes>
		</>
	)
}

function CustomerStatus({customer}) {
	const snoozeMap = {
		true: (
			<Tooltip title={`until ${customer.SnoozedUntil}`} placement="right">
				<SnoozeIcon style={{paddingRight: "0.5rem", fontSize: "2rem"}} />
			</Tooltip>
		),
		false: null,
	}

	const escalationMap = {
		true: (
			<Tooltip title={customer.Escalation} placement="right">
				<WarningIcon style={{paddingRight: "0.5rem", fontSize: "2rem"}} />
			</Tooltip>
		),
		false: null,
	}

	return escalationMap[!!customer.Escalation] || snoozeMap[!!customer.CommunicationSnoozed]
}

export {CustomerCard}
